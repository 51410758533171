import styled from 'styled-components';

export const Container = styled.div`
background-color: black;
height: 10px;
position: sticky;
top: 0;
left: 0;
z-index: 1;
width: 100%;
`

export const ProgressBar = styled.div`
height: 5px;
background-color: lightgreen;
`
export const ScrollContent = styled.div`
overflowY: scroll;
height:100%;
`;

export const Heading = styled.h1`
text-align: center;
font-size: 3rem;
`
