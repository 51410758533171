import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCardContent, IonCardSubtitle, IonItem, IonLabel, IonList, IonText, useIonToast } from "@ionic/react";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/globalState";

import Tag from "./Tag";





export default function RecipeComponent ({mykey, props}){
/* console.log(mykey) */
let tags = props.tags

let ingredients = JSON.parse(props.ingredients)
let directions = JSON.parse(props.directions)
let shortDirections = directions.filter((e,i) => (i < 1))

const [displayedTags, setdisplayedTags] = useState(4)
const [displayedIngredients, setdisplayedIngredients] = useState(4)
const {myRecipes, addRecipeToList, deleteRecipeFromList} = useContext(GlobalContext)
const [present] = useIonToast();



/* useEffect(() => {
    console.log(myRecipes)
}) */

function showMoreTags(){
    if(displayedTags === 4){
        setdisplayedTags(tags.length)
    } else {
        setdisplayedTags(4)
    }
    
}
function showMoreIngredients(){
    if(displayedIngredients === 4){
        setdisplayedIngredients(ingredients.length)
    } else {
        setdisplayedIngredients(4)
    }
}

 async function AddRecipe(){
    /* console.log(myRecipes) */
    let recipeInList = false
    /* console.log(props) */
    for(let el of myRecipes){
        if(el.recipeId === props.recipeId){
            present({message: 'Item already in list', duration: 3000, color: "dark"})
            recipeInList = true
        } 
    }
    if(recipeInList === false){
       await addRecipeToList(props)
        /* await addIngredients(props) */
        present({message: 'Item added to list', duration: 3000, color: "success", position: "bottom"})
    }
    
}
function RemoveRecipe(){
    /* console.log(myRecipes) */
    
    /* console.log(props) */
    
    
        deleteRecipeFromList(props)
        present({message: 'Item removed from list', duration: 3000, color: "success", position: "bottom"})
    
    
}
/* const handleClick = () => {
    window.open("http://twitter.com/saigowthamr");
  }; */
console.log(props)
    return(
        <IonCard mykey={mykey}>
            <IonCardContent style={{textAlign: "center"}}>
            <IonText style={{fontSize: 18}}>
                    <b> {props.title}</b>
                </IonText>
                <img alt={props.title} src={props.imgUrl} />
                
            </IonCardContent>
        
            {!props.myRecipe && <React.Fragment>
            
               


            <IonCardContent >
                    <IonCardSubtitle>
                        Tags
                    </IonCardSubtitle>
                    <IonText>
                        {tags.filter((e,i) => i < displayedTags).map((e, i) => (
                            <Tag key={i} chipTitle={e} />
                        ))}
                    </IonText>
                    <br/>
                   {displayedTags === 4 && <IonButton size="small" onClick={showMoreTags}>Show more</IonButton>}
                   {displayedTags !== 4 && <IonButton size="small" onClick={showMoreTags}>Show less</IonButton>}
                    
                    </IonCardContent>

               

                </React.Fragment>}
              
                {props.myRecipe && <React.Fragment>
                <IonCardContent>
                    <IonCardSubtitle>
                        Instructions
                    </IonCardSubtitle>
                    <IonCardContent>
                    <IonAccordionGroup>
          <IonAccordion value="colors" >
              <IonItem slot="header">
            
              <IonLabel>{"1. "+shortDirections}</IonLabel>
            
            </IonItem>
      
            <IonList slot="content">
            
            <ol >
                        {directions.map((e, i) => (
                            
                                <li key={i} type={i}>
                                    <IonText style={{fontSize:16}}>
                             {e}
                             </IonText>
                             </li>
                 
                        ))}
                             </ol>                                   
                    
                                
                    
            </IonList>
          </IonAccordion>
          </IonAccordionGroup>
                    
                    </IonCardContent>
                    </IonCardContent>


                    <IonCardContent>
                    <IonCardSubtitle>
                        Ingredients
                    </IonCardSubtitle>
                    
                    <IonText>
                        {ingredients.filter((e,i) => (i < displayedIngredients)).map((e,i) => {
                           let shortendTitle = e.slice(0, 90)
                           return<Tag key={i} chipTitle={shortendTitle} />
                    })}
                         <br/>
                   {displayedIngredients === 4 && <IonButton size="small" onClick={showMoreIngredients}>Show more</IonButton>}
                   {displayedIngredients !== 4 && <IonButton size="small" onClick={showMoreIngredients}>Show less</IonButton>}
                    </IonText>
                    </IonCardContent>

                    </React.Fragment>}
                    {!props.myRecipe && <IonCardContent>
                <IonButton class="center-ion-items" onClick={AddRecipe}>
                    Add to List
                </IonButton>
            </IonCardContent>}
                    {props.myRecipe && <IonCardContent>
                <IonButton onClick={RemoveRecipe}>
                    Remove from List
                </IonButton>
            </IonCardContent>}
            <IonCardContent>
                <IonCardSubtitle>
             #{mykey+1}
                </IonCardSubtitle>
            </IonCardContent>
             
        </IonCard>
    )
}