import { createContext, useReducer } from "react"
import AppReducer from "./AppReducer"

import recipeData from '../100DemoRecipesReactCourse.json'

const initialState = {
    myRecipes: [],
    allRecipes: recipeData,
    ingredientShopping: [],
}

export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({children}) => {
    const [state, dispatch] = useReducer(AppReducer, initialState)

   

    function addRecipeToList(recipe){
        recipe['myRecipe'] = true
        dispatch(
            {
                type: "ADD_ITEM",
                payload: recipe
            }
        )
        
        
    }
   
    function getMyCurrentRecipes(recipe){
        dispatch(
            {
                payload: "get"
            }
        ) 
    }
    function deleteRecipeFromList(recipe){
        delete recipe['myRecipe'] 
        dispatch(
            {
                type: "DELETE_ITEM",
                payload: recipe
            }
        )
    }

    return(
        <GlobalContext.Provider value={{myRecipes: state.myRecipes, allRecipes: state.allRecipes, ingredientShopping: state.ingredientShopping, addRecipeToList, deleteRecipeFromList, getMyCurrentRecipes}}>
            {children}
        </GlobalContext.Provider>
    )

}