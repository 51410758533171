import { IonCard, IonCardContent, IonCardSubtitle, IonCol, IonContent, IonHeader, IonInput, IonPage, IonRange, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';

import RecipeComponent from '../components/recipe';
import { GlobalContext } from '../context/globalState';
import { ProgressBar, ScrollContent } from '../theme/scrollIndicatorStyles';
import './Tab1.css';




const Tab1: React.FC = () => {

  const {allRecipes} = useContext(GlobalContext)
  
  const [scroll, setScroll] = useState(0);
  const [shuffleMinValue, setshuffleMinValue] = useState(0);
  const [shuffleMaxValue, setshuffleMaxValue] = useState(5);
  const [recipeFilter, setrecipeFilter] = useState(5);

  useEffect(()=> {
  console.log(allRecipes)
  
  
})

function onScroll(e:any) {

  const Scrolled = e.nativeEvent.srcElement.scrollTop;
  const MaxHeight =
  e.nativeEvent.srcElement.scrollHeight -
  e.nativeEvent.srcElement.clientHeight;
  const ScrollPercent = (Scrolled / MaxHeight) * 100;
  setScroll(ScrollPercent);
  
  console.log(ScrollPercent)
};

function changeRecipeFilter(filterValue:any){
  /* console.log(e.srcElement.value) */
  setrecipeFilter(filterValue)
  shuffle()
}

function shuffle(){
 /*  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  } */
  var i = 0
  var j = 0
  while(j === 0){
    i = Math.floor(Math.random() * (100));
  if(i < 99-recipeFilter){     
     j = i+recipeFilter
     setshuffleMinValue(i)
     setshuffleMaxValue(j)
  } 
  console.log(i)
  console.log(j)
  console.log(j-i)
  }
  
}
  

  return (
    
    <IonPage>
    
      <IonHeader>
        <IonToolbar style={{textAlign: "center"}}>
          <IonTitle  style={{color:"purple"}}>All Recipes</IonTitle>
         
        </IonToolbar>
        <ProgressBar style={{ width: `${scroll}%` }}>
        </ProgressBar>
      </IonHeader>
      <ScrollContent >
  
      <IonContent fullscreen>
      
        <IonHeader collapse="condense">
          <IonToolbar style={{textAlign: "center"}}>
            <IonTitle style={{color:"purple"}} size="large">Recipes </IonTitle>
          </IonToolbar>
         
        
          
        </IonHeader>
       
        {/* <br/>
        <img alt="recipes" src="https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&w=500&h=297&dpr=2"/>  */}
        
       

        
  
      <div style=
        {
        scroll < 10?
          { 
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            
          }
        :
          scroll < 98?
            { 
              height: "100%",
              overflowX: "hidden",
              overflowY: "scroll"}
            :
             {
              height: "100%",
              overflowX: "hidden",
              overflowY: "auto"
            }
        }
        onScroll={(e:any) => onScroll(e)}>
 <IonCard>
        <IonCardContent>
     <IonCol style={{textAlign: "center"}}>
                <IonRow>
                <IonCardSubtitle>
                  Displayed recipes 
                </IonCardSubtitle>

                </IonRow>
                <IonRow>
                <IonInput style={{fontSize: 20}} value={recipeFilter} onIonChange={(e:any) => changeRecipeFilter(e.srcElement.value)} disabled type="number" />

                </IonRow>
                <IonRow>
                <IonRange value={recipeFilter} min={5} step={5} max={20} snaps pin color="dark" onIonChange={(e) => changeRecipeFilter(e.detail.value)}>
                </IonRange>

                </IonRow>
                
                </IonCol>
                </IonCardContent>
                </IonCard>
    
         {allRecipes.filter((e, i)=> i > shuffleMinValue && i <= shuffleMaxValue).map((e, i) => (
          <RecipeComponent key={i} mykey={i} props={e}/>
        ))
        }
          
           </div>
  
       
      </IonContent>
      </ScrollContent>
    </IonPage>

    
  );
};

export default Tab1;
