import { IonChip, IonLabel } from "@ionic/react"


export default function Tag (chipTitle){

/* console.log(chipTitle) */
    return(
        <IonChip>
        <IonLabel>{chipTitle.chipTitle}</IonLabel>
      </IonChip>
    )
}