import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { MyRecipes } from '../components/myRecipes';


import './Tab2.css';



const Tab2: React.FC = () => {
 

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{textAlign: "center"}}>
          <IonTitle  style={{color:"purple"}}>My Recipes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle  style={{color:"purple"}} size="large">My Recipes</IonTitle>
          </IonToolbar>
          
        </IonHeader>
        <img src="https://images.pexels.com/photos/4033634/pexels-photo-4033634.jpeg?auto=compress&cs=tinysrgb&w=500"/>
          <MyRecipes />
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
