import { IonCard, IonCardContent, IonCol, IonRow, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/globalState";


export function ShoppingList(){
    const {ingredientShopping } = useContext(GlobalContext)
    const [selectedUnitSystem, setselectedUnitSystem] = useState("si")
console.log(ingredientShopping)

function changeUnitSystem(unitSystem){
    setselectedUnitSystem(unitSystem)
}
    return(
        
        <IonCard>
      
            
                   <IonCardContent>
                   
            {ingredientShopping.length === 0  && 
                <IonText>Add recipes to your list to see summarized shopping list!</IonText>
            }
            {ingredientShopping.length > 0  && 
                 <IonSelect  name="unit" value={selectedUnitSystem} onIonChange={(e:any) => {changeUnitSystem(e.target.value)}}  >
                 <IonSelectOption  value="si">SI</IonSelectOption>
                 <IonSelectOption  value="imperial">Imperial</IonSelectOption>
                 <IonSelectOption  value="cn">Jin</IonSelectOption>
                 </IonSelect>
            }
            

            {ingredientShopping !== [] && selectedUnitSystem === "si" && ingredientShopping !== undefined && ingredientShopping.map((e,i) => {
                
                    console.log(e)
                    return (<IonRow key={i}>
                            <IonCol>
                                {e.name}
                            </IonCol>
                            <IonCol>
                                {e.quantitySi}
                            </IonCol>
                            <IonCol>
                                {e.siUnit}
                            </IonCol>
                           
                        </IonRow>)
                   })}
            {ingredientShopping !== [] && selectedUnitSystem === "imperial" && ingredientShopping !== undefined && ingredientShopping.map((e,i) => {
                    console.log(e)
                    return (<IonRow>
                            <IonCol>
                                {e.name}
                            </IonCol>
                            <IonCol>
                                {e.quantitySi/500}
                            </IonCol>
                            <IonCol>
                                {"lbs"}
                            </IonCol>
                           
                        </IonRow>)
                   })}
            {ingredientShopping !== [] && selectedUnitSystem === "cn" && ingredientShopping !== undefined && ingredientShopping.map((e,i) => {
                    console.log(e)
                    return (<IonRow>
                            <IonCol>
                                {e.name}
                            </IonCol>
                            <IonCol>
                                {e.quantitySi/500}
                            </IonCol>
                            <IonCol>
                                {"Jin (市斤)"}
                            </IonCol>
                           
                        </IonRow>)
                   })}
                   </IonCardContent>
                   {/* </IonCardContent> */}
        </IonCard>
        
    )
}