

import { IonCard, IonCardContent, IonText } from "@ionic/react"
import React, { useContext } from "react"
import { GlobalContext } from "../context/globalState"

import RecipeComponent from "./recipe"


export function MyRecipes(){

    const {myRecipes} = useContext(GlobalContext)
     
        return(
        
            <React.Fragment>
                
        {myRecipes !== [] && myRecipes.map((e, i) => {
            
           return <RecipeComponent key={i} mykey={i} props={e} />
        })}
        {myRecipes.length === 0 &&
        <IonCard>
            <IonCardContent>
        <IonText style={{fontSize:20}} >
            No recipes selected
        </IonText>
        </IonCardContent>
        </IonCard>
        }
        </React.Fragment>
        )
    
    
      
    
}